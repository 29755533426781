import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const Heading = (heading) => (
  <div className={`${heading.class} card-header`}>
    {heading.content.map((content) => {
      return content;
    })}
  </div>
);

const Body = (body) => (
  <div className={`${body.class} card-body`}>
    {body.content.map((content) => {
      return content;
    })}
  </div>
);

const Footer = (footer) => (
  <div className={`${footer.class} card-footer`}>
    {footer.content.map((content) => {
      return content;
    })}
  </div>
);

const checkSegment = (segment) => {
  if (segment.type === "card-header") {
    return <Heading {...segment} />;
  } else if (segment.type === "card-body") {
    return <Body {...segment} />;
  } else if (segment.type === "card-footer") {
    return <Footer {...segment} />;
  } else if (segment.type === "card-img-top") {
    return (
      <GatsbyImage
        image={segment.imgVariable}
        alt={segment.altText}
        className={`card-image-top border-radius-bottom-0 ${segment.class}`}
      />
    );
  } else {
    return segment;
  }
};

const CardGeneric = (props) => {
  return (
    <div className={`card ${props.cardClass}`}>
      {props.segments.map((segment) => {
        return checkSegment(segment);
      })}
    </div>
  );
};

export default CardGeneric;

CardGeneric.defaultProps = {
  cardClass: "",
  hasGrowEffect: false,
  hasRiseShadowEffect: false,
  segments: [
    // {
    //     type: 'card-header',
    //     content: [],
    //     class: ''
    // },
    // {
    //     type: 'card-body',
    //     content: [],
    //     class: ''
    // },
    // {
    //     type: 'card-footer',
    //     content: [],
    //     class: ''
    // },
    // {
    //     type: 'card-image-top',
    //     imgVariable: "",
    //     altText: "",
    //     class: ''
    // }
  ]
};
